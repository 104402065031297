<template>
  <ToolBar @added-node="addedNode" />
  <MyNodes :added="added" />
</template>

<script>
// @ is an alias to /src
import ToolBar from '@/components/ToolBar.vue'
import MyNodes from '@/components/MyNodes.vue'

export default {
  mounted() {
    this.$store.dispatch('getMicrocosm')
    // register, i.e. in a `beforeDestroy` hook
    window.addEventListener('unload', this.removeLocalStorage)
  },
  name: 'Collect',

  components: {
    ToolBar,
    MyNodes,
  },

  data() {
    return {
      added: false,
    }
  },

  methods: {
    addedNode() {
      this.added = !this.added
    },
    removeLocalStorage() {
      localStorage.removeItem('nogg_microcosm')
      localStorage.removeItem('nogg_name')

      location.assign(
        process.env.VUE_APP_HTTP + '://' + process.env.VUE_APP_URL + '/'
      )
    },
  },
}
</script>

<style scoped></style>
